import React from 'react';
import { Link } from 'gatsby'
// import './style.scss';



const LetterBoxCall = () => (
    <section className="section letterbox is-bold">
        <div className="container has-text-centered">
            <div className="columns is-desktop">
                <div className="column is-three-fifths">
                    <h1 className="title is-size-5-mobile">
                        Speak to a member of our expert team today
                </h1>
                </div>
                <div className="column">
                    <Link className="cta-button button" to="/contact-us">Get in touch</Link>
                </div>
            </div>
        </div>
    </section>
);


export default LetterBoxCall;