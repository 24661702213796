import React from 'react'
import Helmet from '../components/helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import LetterBoxCall from '../components/letterbox-call'

const CookiesPage = () => (
    <div>
        <Helmet />
        <Header />
        <section className="section">
            <div className="container content">
                <h1>Cookies</h1>

                <p>At The Insolvency Helpdesk we take your privacy very seriously, by using this site it is important to understand how we may use your information.</p>

                <h2>How we use Cookies</h2>

                <p>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</p>

                <h2>Functional Cookies</h2>

                This website will:
                <ul>
                    <li>Offer Live Chat Support (if available)</li>
                    <li>Track the pages you visit via Google Analytics</li>
                </ul>
                <h2>Targeting Cookies</h2>

                This website will:
                <ul>
                    <li>Allow you to share pages with social networks such as Facebook (if available)</li>
                </ul>
                This website will not:
                <ul>
                    <li>Share any personal information with third parties</li>
                </ul>
            </div>
        </section>
        <LetterBoxCall />
        <section className="section">
            <div className="container content">
                <h2>Legal Requirements</h2>
                <p>Insolvency Practitioners who provide advice on behalf of The Insolvency Helpdesk are regulated by The Institute of Chartered Accountants in England and Wales.
</p>
                <h2>Complaints</h2>
                <p>If for any reason you are dissatisfied with the services you are receiving, please contact us. We will carefully consider any complaint we receive and, if we believe that we have given a less than satisfactory service, we will take all reasonable steps to put it right. Whilst we undertake to look into any complaint carefully and promptly and to do all we can to explain the position to you, if you remain unsatisfied, you have the right to refer the matter to the Insolvency Complaints Gateway which is operated by the Insolvency Service, an Executive Agency of the Department for Business, Energy & Industrial Strategy (“BEIS”). Complaints can be submitted via an online complaints form at www.gov.uk/complain-about-insolvency-practitioner (Guidance for those who wish to complain can also be found on this site). If you have difficulty accessing the online complaints form you can also make any complaint through the Insolvency Service Enquiry Line – email insolvency.enquiryline@insolvency.gsi.gov.uk or alternatively telephone 0300 678 0015 (Monday to Friday 9am to 5pm)</p>
            </div>
        </section>
        <Footer />
    </div>
)

export default CookiesPage